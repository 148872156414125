import { computed, reactive, toRef } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useCacheData, useSharedPromise } from '#imports'
import { useProducts } from '@/stores/products'

import type {
  Product,
  ProductDetails
} from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import type {
  AvailabilityDetails,
  ReplacementProduct,
  VintageExpertRates
} from '@/modules/nuxt-api/models/Product'
import type { Review } from '@/modules/nuxt-api/models/Review'

type State = {
  currentProduct: Product.AsObject | undefined
  currentSimilarProducts: Product.AsObject[]
  currentAvailability: Partial<AvailabilityDetails> | undefined
  currentProductCode: Product.AsObject['code'] | undefined
}

const state = reactive<State>({
  currentProduct: undefined,
  currentSimilarProducts: [],
  currentAvailability: undefined,
  currentProductCode: undefined
})

export function useProductStore () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()

  function clearCurrentData () {
    state.currentProduct = undefined
    state.currentSimilarProducts = []
  }

  async function getProduct (code: Product.AsObject['code'], withDetails = true) {
    if (!code) {
      return { product: undefined, details: undefined, replacement: undefined }
    }

    state.currentProductCode = code

    const { value, addToCache } = await useCacheData<
      [
        Product.AsObject | undefined,
        ProductDetails.AsObject | undefined,
        ReplacementProduct | undefined
      ]
    >(['product', code])

    let currentProduct: Product.AsObject | undefined, currProductDetails: ProductDetails.AsObject | undefined, currProductReplacement

    if (value) {
      currentProduct = value[0]
      currProductDetails = value[1]
      currProductReplacement = value[2]
    } else {
      const { product, details, replacement } = await nuxtApp.runWithContext(() =>
        useSharedPromise(['product', code], async () => {
          const { requireProduct } = api.product()

          return await requireProduct(code, withDetails)
        })
      )

      currentProduct = product as Product.AsObject
      currProductDetails = details
      currProductReplacement = replacement

      if (product && currProductDetails) {
        await addToCache([product, currProductDetails, currProductReplacement])
      }
    }

    state.currentProduct = currentProduct

    return {
      product: currentProduct,
      details: currProductDetails,
      replacement: currProductReplacement
    }
  }

  async function getReviewsForProduct (id: number) {
    const { value, addToCache } = await useCacheData<Review[]>([
      'product-reviews',
      id.toString()
    ])

    let currReviewsForProduct

    if (value) {
      currReviewsForProduct = value
    } else {
      currReviewsForProduct = await nuxtApp.runWithContext(() =>
        useSharedPromise(['product-reviews', id.toString()], async () => {
          const { getProductReviews } = api.reviews()
          const [reviews] = await getProductReviews(id, { size: 100, cursor: '0' })

          if (reviews?.length) {
            await addToCache(reviews)
          }

          return reviews
        })
      )
    }

    return currReviewsForProduct
  }

  async function getProductVintagesExpertRates (code: Product.AsObject['code']) {
    const { value, addToCache } = await useCacheData<VintageExpertRates[]>([
      'product-vintages',
      code
    ])

    if (value) {
      return value
    } else {
      const { getProductVintagesExpertRates } = api.product()
      const vintagesExpertRates = await getProductVintagesExpertRates(code)

      if (vintagesExpertRates?.length) {
        await addToCache(vintagesExpertRates)
      }

      return vintagesExpertRates
    }
  }

  async function getProductShortAvailability (variationIdsList: number[] = [], id?: number) {
    if (id) {
      const idsList = Array.from(new Set([...variationIdsList, id]))
      const availabilityData = await useProducts().getProductAvailability(idsList)
      state.currentAvailability = { availability: availabilityData[id] }
      return state.currentAvailability
    }

    return undefined
  }

  async function getProductDetailedAvailability () {
    const code = state.currentProductCode
    if (!code) {
      return undefined
    }

    const { getProductDetailedAvailability } = api.product()
    state.currentAvailability = await getProductDetailedAvailability(code).catch(
      () => undefined
    )

    return state.currentAvailability
  }

  function updateSimilarProducts (products?: Product.AsObject[]) {
    if (products?.length) {
      state.currentSimilarProducts = products
    }
  }

  function updateCurrentProduct (
    code: string,
    product?: Product.AsObject,
    availability?: Partial<AvailabilityDetails> | undefined,
    similarProducts?: Product.AsObject[]
  ) {
    if (code !== state.currentProductCode) {
      clearCurrentData()
      state.currentProductCode = code
    }

    if (product) {
      state.currentProduct = product
    }

    if (availability) {
      state.currentAvailability = availability
    }

    updateSimilarProducts(similarProducts)
  }

  const hasSimilarAboveFive = computed(() => {
    return (state.currentSimilarProducts?.length ?? 0) > 5
  })

  return {
    clearCurrentData,
    getProduct,
    getReviewsForProduct,
    getProductShortAvailability,
    getProductDetailedAvailability,
    updateCurrentProduct,
    hasSimilarAboveFive,
    getProductVintagesExpertRates,
    updateSimilarProducts,

    currentProduct: toRef(state, 'currentProduct'),
    currentSimilarProducts: toRef(state, 'currentSimilarProducts'),
    currentAvailability: toRef(state, 'currentAvailability'),
    currentProductCode: toRef(state, 'currentProductCode')
  }
}
